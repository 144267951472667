import React from 'react'
import Layout from '../components/layout'
import { getItemFromStore } from '../helpers/hash'
import { useLocalStorage } from '@mantine/hooks'

export default function Thanks() {
  const [formValue, setFormValue] = useLocalStorage({
    key: 'form',
  })

  if (formValue && formValue.Firstname) {
    return (
      <Layout>
        <section className="section bg-gray-50">
          <div className="mx-auto px-4 py-16 pb-24 max-w-lg">
            <h1 className="font-semibold text-2xl md:text-3xl text-center">
              You're all set.
            </h1>
            <p className="text-center text-gray-700">
              Thank you, {formValue.Firstname}! We have received your request
              for an estimate and will be in touch soon. Look out for our
              emails. We will be contacting you at the following address:
            </p>
            <div>
              <div className="my-6 bg-white s rounded-xl p-4 shadow-lg">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-2 md:py-3   sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-600">Full name</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formValue.Firstname} {formValue.Lastname}
                    </dd>
                  </div>
                  <div class="py-2 md:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-600">
                      Email address
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formValue.email}
                    </dd>
                  </div>
                  <div class="py-2 md:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-600">Phone</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formValue.phone}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
  return (
    <Layout>
      <section className="section bg-gray-50">
        <div className="mx-auto px-4 py-16 pb-24 max-w-lg">
          <h1 className="font-semibold text-2xl md:text-3xl text-center">
            You're all set.
          </h1>
          <p className="text-center text-gray-700">
            Thank you! We have received your request for an estimate and will be
            in touch soon. Look out for our emails. We will be contacting you
            soon.
          </p>
          <div></div>
        </div>
      </section>
    </Layout>
  )
}
